import React, { useState, useEffect, useCallback } from 'react';
import './App.css';

// Fonction magique pour importer toutes les images dans le dossier src/images
function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context('./images', true, /\.(png|jpe?g|gif|svg)$/)
);

function App() {
  const [shuffledImages, setShuffledImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Fonction pour mélanger les images (algorithme de Fisher-Yates)
  const shuffleArray = (array) => {
    const arr = [...array];
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
  };

  useEffect(() => {
    setShuffledImages(shuffleArray(images));
  }, []);

  // Sensibilité du scroll : plus la valeur est élevée, moins l'utilisateur doit défiler pour changer de slide.
  const sensitivity = 2;

  // Utilisation de useCallback pour rendre la fonction stable et éviter les avertissements
  const handleScroll = useCallback(() => {
    const threshold = window.innerHeight / sensitivity;
    const scrollY = window.scrollY;
    const index = Math.min(Math.floor(scrollY / threshold), shuffledImages.length - 1);
    setCurrentImageIndex(index);
  }, [sensitivity, shuffledImages]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  // Auto-scroll toutes les 5 secondes
  useEffect(() => {
    if (shuffledImages.length === 0) return;
    const threshold = window.innerHeight / sensitivity;
    const scrollInterval = setInterval(() => {
      const nextIndex = (currentImageIndex + 1) % shuffledImages.length;
      window.scrollTo({
        top: nextIndex * threshold,
        behavior: 'smooth',
      });
      setCurrentImageIndex(nextIndex);
    }, 5000);
    return () => clearInterval(scrollInterval);
  }, [currentImageIndex, shuffledImages, sensitivity]);

  // Gestion du clavier : flèche droite pour le slide suivant (plus rapide), flèche gauche pour le précédent
  const handleKeyDown = useCallback((e) => {
    if (shuffledImages.length === 0) return;
    // Accélérer le changement d'image via le clavier en diminuant le seuil
    const keySensitivity = sensitivity * 1.5;
    const threshold = window.innerHeight / keySensitivity;
    if (e.key === 'ArrowRight') {
      const nextIndex = (currentImageIndex + 1) % shuffledImages.length;
      window.scrollTo({
        top: nextIndex * threshold,
        behavior: 'smooth',
      });
      setCurrentImageIndex(nextIndex);
    } else if (e.key === 'ArrowLeft') {
      const prevIndex = (currentImageIndex - 1 + shuffledImages.length) % shuffledImages.length;
      window.scrollTo({
        top: prevIndex * threshold,
        behavior: 'smooth',
      });
      setCurrentImageIndex(prevIndex);
    }
  }, [currentImageIndex, shuffledImages, sensitivity]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  return (
    <div className="App">
      <div className="slideshow">
        {shuffledImages.map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`Slide ${index + 1}`}
            className={index === currentImageIndex ? 'active' : ''}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        ))}
      </div>

      <div className="content">
        <h1>________________Pouydesseau Vincent</h1>
        <p>
          __________________vincentpouydesseau.com{' '}
          <a href="https://linktr.ee/pouydesseau" target="_blank" rel="noopener noreferrer">
            more
          </a>
        </p>
        <div
          style={{
            height: `${shuffledImages.length * (window.innerHeight / sensitivity)}px`,
          }}
        ></div>
      </div>
    </div>
  );
}

export default App;
